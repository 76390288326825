// src/components/CheckIp.js
import React, { useState, useEffect, useRef } from 'react';
import './CheckIp.css';
import Layout from './Layout';
import AdPlaceholder from './AdPlaceholder'; // Add this import
import {
    MapPin, Globe, Building, Map as MapIcon, Network, Compass, Flag, User, Search, Copy,
} from 'lucide-react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Helmet } from 'react-helmet';

// Fix for marker icons not showing up
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

const CheckIp = () => {
    const [ipInfo, setIpInfo] = useState(null);
    const [ipAddress, setIpAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOwnIp, setIsOwnIp] = useState(true);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);

    const mapContainerRef = useRef(null);

    const fetchIpInfo = (ip) => {
        setLoading(true);
        setError(null);

        const apiUrl = ip ? `https://ipapi.co/${ip}/json/` : 'https://ipapi.co/json/';

        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    setError(data.reason || 'Invalid IP address.');
                    setLoading(false);
                    setIpInfo(null);
                } else {
                    setIpInfo(data);
                    setIsOwnIp(!ip);
                    setLoading(false);
                    // No need to call initializeMap here
                }
            })
            .catch((error) => {
                console.error('Error fetching IP information:', error);
                setError('An error occurred while fetching IP information.');
                setLoading(false);
                setIpInfo(null);
            });
    };

    // Use useEffect to initialize the map after ipInfo has been set
    useEffect(() => {
        if (ipInfo && mapContainerRef.current) {
            initializeMap(ipInfo);
        }
    }, [ipInfo]);

    const initializeMap = (data) => {
        // Ensure the map container exists
        if (!mapContainerRef.current) {
            console.error('Map container not found.');
            return;
        }

        // Remove existing map if it exists
        if (window.map) {
            window.map.remove();
        }

        const latitude = parseFloat(data.latitude);
        const longitude = parseFloat(data.longitude);

        if (isNaN(latitude) || isNaN(longitude)) {
            console.error('Invalid latitude or longitude values.');
            return;
        }

        const map = L.map(mapContainerRef.current).setView([latitude, longitude], 13);
        window.map = map; // Store map instance globally

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors',
        }).addTo(map);

        L.marker([latitude, longitude])
            .addTo(map)
            .bindPopup(`${data.city}, ${data.region}, ${data.country_name}`)
            .openPopup();
    };

    useEffect(() => {
        fetchIpInfo('');
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchIpInfo(ipAddress.trim());
    };

    const copyToClipboard = () => {
        if (ipInfo && ipInfo.ip) {
            navigator.clipboard.writeText(ipInfo.ip).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            });
        }
    };

    return (
        <Layout>
            <Helmet>
                <title>CheckNet - IP</title>
            </Helmet>

            <div className="container my-5">
                {ipInfo && (
                    <div className="ip-title-highlight">
                        <h1>
                            {isOwnIp ? 'Your IP is: ' : 'Information for IP: '}
                            {ipInfo.ip}
                        </h1>
                        {isOwnIp && (
                            <button
                                onClick={copyToClipboard}
                                className="copy-button"
                                title="Copy IP to clipboard"
                            >
                                {copied ? 'Copied!' : <Copy size={32} />} {/* Increased icon size */}
                            </button>
                        )}
                    </div>
                )}

                {/* Search Form */}
                <form onSubmit={handleSubmit} className="mb-4">
                    {/* ... (keep existing form content) */}
                </form>

                {/* Add AdPlaceholder here */}
                {/* <AdPlaceholder /> */}

                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}

                {/* Search Form */}
                <form onSubmit={handleSubmit} className="mb-5">
                    <div className="input-group input-group-lg">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter IP address (e.g., 8.8.8.8)"
                            value={ipAddress}
                            onChange={(e) => setIpAddress(e.target.value)}
                        />
                        <button className="btn btn-primary" type="submit">
                            <Search size={24} /> Lookup
                        </button>
                    </div>
                </form>

                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}

                {loading ? (
                    <p>Loading IP information...</p>
                ) : ipInfo ? (
                    <div className="row ip-info-container">
                        {/* IP Information Card */}
                        <div className="col-md-6">
                            <div className="card h-100">
                                <div className="card-header bg-primary text-white">
                                    <h5 className="card-title mb-0">
                                        <MapPin size={24} /> IP Information
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <strong><User size={20} /> IP Address:</strong> {ipInfo.ip}
                                        </li>
                                        <li className="list-group-item">
                                            <strong><Flag size={20} /> Country:</strong> {ipInfo.country_name} ({ipInfo.country})
                                        </li>
                                        <li className="list-group-item">
                                            <strong><Compass size={20} /> Region:</strong> {ipInfo.region}
                                        </li>
                                        <li className="list-group-item">
                                            <strong><Building size={20} /> City:</strong> {ipInfo.city}
                                        </li>
                                        <li className="list-group-item">
                                            <strong><MapIcon size={20} /> Postal Code:</strong> {ipInfo.postal}
                                        </li>
                                        <li className="list-group-item">
                                            <strong><Network size={20} /> ISP:</strong> {ipInfo.org}
                                        </li>
                                        <li className="list-group-item">
                                            <strong><Globe size={20} /> Timezone:</strong> {ipInfo.timezone}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Map */}
                        <div className="col-md-6">
                            <div className="card h-100">
                                <div className="card-header bg-primary text-white">
                                    <h5 className="card-title mb-0">
                                        <MapIcon size={24} /> Location Map
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div ref={mapContainerRef} className="map-container"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    !error && <p>No IP information available.</p>
                )}
            </div>
        </Layout>
    );
};

export default CheckIp;
