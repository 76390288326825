// src/components/Layout.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Layout.css';
import tools from '../config/toolsConfig';

const Layout = ({ children }) => {
    const location = useLocation();

    return (
        <div className="layout-container">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link to="/" className={`navbar-brand ${location.pathname === '/' ? 'active' : ''}`}>CheckNet</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            {tools.map((tool, index) => (
                                <li key={index} className="nav-item">
                                    <Link
                                        to={tool.link}
                                        className={`nav-link ${location.pathname === tool.link ? 'active' : ''}`}
                                    >
                                        {tool.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>

            <main className="flex-grow-1">{children}</main>

            <footer className="bg-dark text-light py-3 mt-auto">
                <div className="container text-center">
                    <p className="mb-0">© 2024 CheckNet. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Layout;