// src/components/MainPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './MainPage.css';
import Layout from './Layout';
import netToolsBackground from '../images/net-tools.jpg';
import tools from '../config/toolsConfig';

const MainPage = () => {
  return (
    <Layout>
      <div className="hero-section" style={{ backgroundImage: `url(${netToolsBackground})` }}>
        <div className="container text-center hero-content">
          <h1 className="hero-title">Welcome to CheckNet</h1>
          <p>Your one-stop destination for essential network tools.</p>
        </div>
      </div>

      <div className="container my-5">
        <div className="row justify-content-center">
          {tools.map((tool, index) => (
            <div key={index} className="col-md-4 mb-4">
              <Link to={tool.link} className="text-decoration-none">
                <div className="card h-100">
                  <div className="card-body text-center">
                    <div className="tool-icon mb-3">{tool.icon}</div>
                    <h5 className="card-title">{tool.title}</h5>
                    <p className="card-text">{tool.description}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default MainPage;