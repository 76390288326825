// src/config/toolsConfig.js
import React from 'react';
import { MapPin } from 'lucide-react';
// import { MapPin, Globe, Clock, Code, FileJson, Server } from 'lucide-react';

const tools = [
    { title: 'Check IP', description: 'Find information about any IP address.', link: '/checkip', icon: <MapPin size={24} color="#4285F4" /> },
    // { title: 'Check Whois', description: 'Retrieve WHOIS information for domains.', link: '/check-whois', icon: <Globe size={24} color="#34A853" /> },
    // { title: 'Check Domain', description: 'Check domain availability and get suggestions.', link: '/check-domain', icon: <Clock size={24} color="#FBBC05" /> },
    // { title: 'Base64 Encoder/Decoder', description: 'Encode or decode Base64 strings.', link: '/base64-encoder-decoder', icon: <Code size={24} color="#4285F4" /> },
    // { title: 'JSON Formatter', description: 'Format and validate JSON data.', link: '/json-formatter', icon: <FileJson size={24} color="#EA4335" /> },
    // { title: 'DNS Lookup', description: 'Get DNS records for any domain.', link: '/dns-lookup', icon: <Server size={24} color="#34A853" /> },
];

export default tools;